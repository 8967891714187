@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2 */
      url('Montserrat_300.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gnD-A.woff */
      url('Montserrat_300.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gnD-g.eot */
      url('Montserrat_300.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/l/font?kit=JTURjIg1_i6t8kCHKm45_cJD3gnD-Q&skey=1ce67628c9aa416a&v=v14#Montserrat */
      url('Montserrat_300.svg#Montserrat') format('svg'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gnD-w.ttf */
      url('Montserrat_300.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2 */
      url('Montserrat_400.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WlhzQ.woff */
      url('Montserrat_400.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhzw.eot */
      url('Montserrat_400.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/l/font?kit=JTUSjIg1_i6t8kCHKm459WlhzA&skey=7bc19f711c0de8f&v=v14#Montserrat */
      url('Montserrat_400.svg#Montserrat') format('svg'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf */
      url('Montserrat_400.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2 */
      url('Montserrat_500.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD-A.woff */
      url('Montserrat_500.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD-g.eot */
      url('Montserrat_500.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/l/font?kit=JTURjIg1_i6t8kCHKm45_ZpC3gnD-Q&skey=7675f1290281829e&v=v14#Montserrat */
      url('Montserrat_500.svg#Montserrat') format('svg'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD-w.ttf */
      url('Montserrat_500.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2 */
      url('Montserrat_700.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD-A.woff */
      url('Montserrat_700.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD-g.eot */
      url('Montserrat_700.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/l/font?kit=JTURjIg1_i6t8kCHKm45_dJE3gnD-Q&skey=11a939c399e8c9fe&v=v14#Montserrat */
      url('Montserrat_700.svg#Montserrat') format('svg'),
    /* from https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD-w.ttf */
      url('Montserrat_700.ttf') format('truetype');
}
